import { Link } from "@remix-run/react";

interface LandingPageProps {
    brandName: string;
    contactEmail: string;
    websiteUrl: string;
}

export default function LandingPage({ brandName, contactEmail, websiteUrl }: LandingPageProps) {
    return (
        <div className="max-w-[1200px] mx-auto text-gray-800 leading-relaxed">
            <header className="flex justify-between items-center py-6">
                <div className="flex items-center gap-2">
                    <div>
                        <div className="text-xl font-semibold text-fm-green">{brandName}</div>
                        <div className="text-xs text-gray-500">by Brih Solutions</div>
                    </div>
                </div>
                <nav className="flex gap-6">
                    {["About", "Features", "Impact", "Contact"].map((item) => (
                        <a
                            key={item}
                            href={`#${item.toLowerCase()}`}
                            className="text-gray-600 hover:text-fm-green transition-colors"
                        >
                            {item}
                        </a>
                    ))}
                </nav>
            </header>

            <main className="space-y-16">
                <section className="text-center bg-fm-light rounded-xl p-8 shadow-md transform hover:-translate-y-1 transition-all duration-300">
                    <h1 className="text-4xl font-bold mb-4 text-fm-green">Revolutionizing Vegetable Distribution</h1>
                    <p className="text-xl text-gray-600 max-w-2xl mx-auto">Connecting wholesalers to retailers with cutting-edge technology</p>
                </section>

                <section className="grid md:grid-cols-2 gap-8">
                    <div className="bg-fm-light rounded-xl p-8 shadow-md transform hover:-translate-y-1 transition-all duration-300">
                        <h2 className="text-2xl font-semibold mb-4">About {brandName}</h2>
                        <p className="text-gray-600 mb-6">
                            {brandName} is a pioneering platform that streamlines the vegetable supply chain,
                            connecting wholesalers with retailers efficiently and reducing waste.
                        </p>
                        <h3 className="text-xl font-semibold mb-4">Our Customers</h3>
                        <div className="grid grid-cols-4 gap-8 px-4">
                            {[...Array(8)].map((_, index) => (
                                <div key={index}
                                     className="bg-white p-3 rounded-full w-20 h-20 flex items-center justify-center shadow-sm hover:shadow-md transition-shadow">
                                    <img
                                        src={`/customer${index + 1}.png?height=60&width=60`}
                                        alt={`Customer logo ${index + 1}`}
                                        className="w-14 h-14 object-contain"
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="relative group h-full">
                        <div
                            className="absolute -inset-[1px] bg-gradient-to-r from-fm-green to-fm-hover rounded-xl opacity-75 group-hover:opacity-100 transition duration-300"></div>
                        <div
                            className="relative bg-white rounded-xl p-8 shadow-lg h-full flex flex-col justify-between">
                            <div className="space-y-8">
                                <div className="flex justify-between items-center">
                                    <h2 className="text-2xl font-semibold">Login to mNet</h2>
                                    <img
                                        src="/mnet-logo.svg"
                                        alt="mNet Logo"
                                        width={100}
                                        height={100}
                                    />
                                </div>

                                <ul className="space-y-4">
                                    <li className="flex items-center gap-2">
                                        <svg className="w-5 h-5 text-fm-green" fill="none" strokeLinecap="round"
                                             strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
                                             stroke="currentColor">
                                            <path d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        <span>Launch your own app within a week</span>
                                    </li>
                                    <li className="flex items-center gap-2">
                                        <svg className="w-5 h-5 text-fm-green" fill="none" strokeLinecap="round"
                                             strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
                                             stroke="currentColor">
                                            <path d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        <span>Go online and increase your sales</span>
                                    </li>
                                    <li className="flex items-center gap-2">
                                        <svg className="w-5 h-5 text-fm-green" fill="none" strokeLinecap="round"
                                             strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
                                             stroke="currentColor">
                                            <path d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        <span>Build your own brand and gain more trust</span>
                                    </li>
                                    <li className="flex items-center gap-2">
                                        <svg className="w-5 h-5 text-fm-green" fill="none" strokeLinecap="round"
                                             strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
                                             stroke="currentColor">
                                            <path d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        <span>Streamline customer communications</span>
                                    </li>
                                    <li className="flex items-center gap-2">
                                        <svg className="w-5 h-5 text-fm-green" fill="none" strokeLinecap="round"
                                             strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
                                             stroke="currentColor">
                                            <path d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        <span>Send timely updates and reminders</span>
                                    </li>
                                    <li className="flex items-center gap-2">
                                        <svg className="w-5 h-5 text-fm-green" fill="none" strokeLinecap="round"
                                             strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
                                             stroke="currentColor">
                                            <path d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        <span>Share promotional messages</span>
                                    </li>
                                </ul>
                            </div>

                            <Link
                                to="/login"
                                className="block w-full text-center px-6 py-3 bg-fm-green text-white rounded-md hover:bg-fm-hover transition-colors font-semibold mt-8"
                            >
                                Login to mNet
                            </Link>
                        </div>
                    </div>
                </section>

                <section id="features"
                         className="bg-fm-light rounded-xl p-8 shadow-md transform hover:-translate-y-1 transition-all duration-300">
                    <h2 className="text-2xl font-semibold mb-6 text-center">Empowering the Supply Chain</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        <div className="bg-white p-6 rounded-lg shadow-sm">
                            <h3 className="text-lg font-medium text-fm-green mb-3">Vast Network</h3>
                            <p className="text-gray-600">Access over 8,000 retailers, vegetable shops, hotels, and
                                supermarkets.</p>
                        </div>
                        <div className="bg-white p-6 rounded-lg shadow-sm">
                            <h3 className="text-lg font-medium text-fm-green mb-3">Dedicated Sales Force</h3>
                            <p className="text-gray-600">Benefit from our network of sales executives to maximize your
                                sales.</p>
                        </div>
                        <div className="bg-white p-6 rounded-lg shadow-sm">
                            <h3 className="text-lg font-medium text-fm-green mb-3">Tech-Driven Operations</h3>
                            <p className="text-gray-600">Utilize our tech tools to streamline and scale your business
                                operations.</p>
                        </div>
                    </div>
                </section>

                <section id="impact"
                         className="bg-fm-light rounded-xl p-8 shadow-md transform hover:-translate-y-1 transition-all duration-300">
                    <h2 className="text-2xl font-semibold mb-6 text-center">Our Impact</h2>
                    <div className="flex flex-col md:flex-row justify-around text-center mb-6">
                        {[
                            {number: "10,000+", label: "Tonnes of Produce Sold"},
                            {number: "8,000+", label: "Active Customers"},
                            {number: "180,000+", label: "Successful Deliveries"}
                        ].map((stat, index) => (
                            <div key={index} className="mb-4 md:mb-0">
                                <span className="block text-3xl font-semibold text-fm-green">{stat.number}</span>
                                <span className="text-gray-600">{stat.label}</span>
                            </div>
                        ))}
                    </div>
                </section>

                <section id="contact"
                         className="bg-fm-light rounded-xl p-8 shadow-md transform hover:-translate-y-1 transition-all duration-300">
                    <h2 className="text-2xl font-semibold mb-4 text-center">Join the Revolution</h2>
                    <p className="text-gray-600 mb-6 max-w-2xl mx-auto text-center">
                        Whether you're a retailer or wholesaler, {brandName} is here to propel your business forward.
                    </p>
                    <div className="flex justify-center gap-4">
                        <a
                            href={`mailto:${contactEmail}`}
                            className="px-6 py-3 bg-fm-green text-white rounded-md hover:bg-fm-hover transition-colors"
                        >
                            Contact Us
                        </a>
                        <a
                            href="tel:9036728830"
                            className="px-6 py-3 border border-fm-green text-fm-green rounded-md hover:bg-fm-light transition-colors"
                        >
                            Call Us
                        </a>
                    </div>
                </section>
            </main>

            <footer className="text-center py-8 text-gray-600">
                <p>&copy; 2024 {brandName} by Brih Solutions Private Limited</p>
                <a
                    href={`https://${websiteUrl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-fm-green hover:underline"
                >
                    {websiteUrl}
                </a>
            </footer>
        </div>
    );
}
